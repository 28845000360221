//export const BASE_URL = 'https://gps.dominos.com.my/api/';
//export const WEBSOCKET_BASE_URL = 'wss://gps.dominos.com.my/websocket/customerco';
//export const BASE_URL = 'http://192.168.0.61:52103/api/';
//export const WEBSOCKET_BASE_URL = 'ws://192.168.0.61:52103/websocket/customerco';
export const BASE_URL = 'https://dfstestbed.whenso.com/api/';
export const WEBSOCKET_BASE_URL = 'wss://dfstestbed.whenso.com/websocket/customerco';
export const API_KEY = '23rusdaljk203ad2bl';
export const DEBUG = false;
export const VERSION = '0.2.41';
export const REFRESH_MILISECOND = 60000;

